export const AtomsAtomLoader = () => import('../../components/atoms/AtomLoader.vue' /* webpackChunkName: "components/atoms-atom-loader" */).then(c => wrapFunctional(c.default || c))
export const AtomsProgressBar = () => import('../../components/atoms/ProgressBar.vue' /* webpackChunkName: "components/atoms-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismFilterUniverse = () => import('../../components/organisms/OrganismFilterUniverse.vue' /* webpackChunkName: "components/organisms-organism-filter-universe" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismInfoPopup = () => import('../../components/organisms/OrganismInfoPopup.vue' /* webpackChunkName: "components/organisms-organism-info-popup" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBar = () => import('../../components/organisms/OrganismMenuBar.vue' /* webpackChunkName: "components/organisms-organism-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBarEmbedProject = () => import('../../components/organisms/OrganismMenuBarEmbedProject.vue' /* webpackChunkName: "components/organisms-organism-menu-bar-embed-project" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBarOffer = () => import('../../components/organisms/OrganismMenuBarOffer.vue' /* webpackChunkName: "components/organisms-organism-menu-bar-offer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBarProject = () => import('../../components/organisms/OrganismMenuBarProject.vue' /* webpackChunkName: "components/organisms-organism-menu-bar-project" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBarUniverse = () => import('../../components/organisms/OrganismMenuBarUniverse.vue' /* webpackChunkName: "components/organisms-organism-menu-bar-universe" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismModalLeavePage = () => import('../../components/organisms/OrganismModalLeavePage.vue' /* webpackChunkName: "components/organisms-organism-modal-leave-page" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismModals = () => import('../../components/organisms/OrganismModals.vue' /* webpackChunkName: "components/organisms-organism-modals" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismProjectInfo = () => import('../../components/organisms/OrganismProjectInfo.vue' /* webpackChunkName: "components/organisms-organism-project-info" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismSidebar = () => import('../../components/organisms/OrganismSidebar.vue' /* webpackChunkName: "components/organisms-organism-sidebar" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismSidebarUniverse = () => import('../../components/organisms/OrganismSidebarUniverse.vue' /* webpackChunkName: "components/organisms-organism-sidebar-universe" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeBuildingOrientationBar = () => import('../../components/molecules/MoleculeBuildingOrientationBar.vue' /* webpackChunkName: "components/molecules-molecule-building-orientation-bar" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeBuildingSpaceSwitchFloor = () => import('../../components/molecules/MoleculeBuildingSpaceSwitchFloor.vue' /* webpackChunkName: "components/molecules-molecule-building-space-switch-floor" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeBuildingZoomSlider = () => import('../../components/molecules/MoleculeBuildingZoomSlider.vue' /* webpackChunkName: "components/molecules-molecule-building-zoom-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeFilterTriggerUniverse = () => import('../../components/molecules/MoleculeFilterTriggerUniverse.vue' /* webpackChunkName: "components/molecules-molecule-filter-trigger-universe" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeMenuTrigger = () => import('../../components/molecules/MoleculeMenuTrigger.vue' /* webpackChunkName: "components/molecules-molecule-menu-trigger" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeMenuTriggerUniverse = () => import('../../components/molecules/MoleculeMenuTriggerUniverse.vue' /* webpackChunkName: "components/molecules-molecule-menu-trigger-universe" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeProjectResult = () => import('../../components/molecules/MoleculeProjectResult.vue' /* webpackChunkName: "components/molecules-molecule-project-result" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeUniverseOrientationBar = () => import('../../components/molecules/MoleculeUniverseOrientationBar.vue' /* webpackChunkName: "components/molecules-molecule-universe-orientation-bar" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateLanding = () => import('../../components/templates/TemplateLanding.vue' /* webpackChunkName: "components/templates-template-landing" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateProject = () => import('../../components/templates/TemplateProject.vue' /* webpackChunkName: "components/templates-template-project" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateProjectSpace = () => import('../../components/templates/TemplateProjectSpace.vue' /* webpackChunkName: "components/templates-template-project-space" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomBulletSpecs = () => import('../../components/atoms/common/AtomBulletSpecs.vue' /* webpackChunkName: "components/atoms-common-atom-bullet-specs" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomButton = () => import('../../components/atoms/common/AtomButton.vue' /* webpackChunkName: "components/atoms-common-atom-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomButtonLink = () => import('../../components/atoms/common/AtomButtonLink.vue' /* webpackChunkName: "components/atoms-common-atom-button-link" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCheck = () => import('../../components/atoms/common/AtomCheck.vue' /* webpackChunkName: "components/atoms-common-atom-check" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCircleProgress = () => import('../../components/atoms/common/AtomCircleProgress.vue' /* webpackChunkName: "components/atoms-common-atom-circle-progress" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCircleProgressLoader = () => import('../../components/atoms/common/AtomCircleProgressLoader.vue' /* webpackChunkName: "components/atoms-common-atom-circle-progress-loader" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomContentText = () => import('../../components/atoms/common/AtomContentText.vue' /* webpackChunkName: "components/atoms-common-atom-content-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCustomButton = () => import('../../components/atoms/common/AtomCustomButton.vue' /* webpackChunkName: "components/atoms-common-atom-custom-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomDropdown = () => import('../../components/atoms/common/AtomDropdown.vue' /* webpackChunkName: "components/atoms-common-atom-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomDropdownFilter = () => import('../../components/atoms/common/AtomDropdownFilter.vue' /* webpackChunkName: "components/atoms-common-atom-dropdown-filter" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomEmptyPlaceholder = () => import('../../components/atoms/common/AtomEmptyPlaceholder.vue' /* webpackChunkName: "components/atoms-common-atom-empty-placeholder" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomFacts = () => import('../../components/atoms/common/AtomFacts.vue' /* webpackChunkName: "components/atoms-common-atom-facts" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomFilterButton = () => import('../../components/atoms/common/AtomFilterButton.vue' /* webpackChunkName: "components/atoms-common-atom-filter-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomIcon = () => import('../../components/atoms/common/AtomIcon.vue' /* webpackChunkName: "components/atoms-common-atom-icon" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomIconAndText = () => import('../../components/atoms/common/AtomIconAndText.vue' /* webpackChunkName: "components/atoms-common-atom-icon-and-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomImageTitle = () => import('../../components/atoms/common/AtomImageTitle.vue' /* webpackChunkName: "components/atoms-common-atom-image-title" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomInfoPopup = () => import('../../components/atoms/common/AtomInfoPopup.vue' /* webpackChunkName: "components/atoms-common-atom-info-popup" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomInput = () => import('../../components/atoms/common/AtomInput.vue' /* webpackChunkName: "components/atoms-common-atom-input" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLinesBullet = () => import('../../components/atoms/common/AtomLinesBullet.vue' /* webpackChunkName: "components/atoms-common-atom-lines-bullet" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLoaderLoadingBar = () => import('../../components/atoms/common/AtomLoaderLoadingBar.vue' /* webpackChunkName: "components/atoms-common-atom-loader-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLoaderProject = () => import('../../components/atoms/common/AtomLoaderProject.vue' /* webpackChunkName: "components/atoms-common-atom-loader-project" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLoaderUniverse = () => import('../../components/atoms/common/AtomLoaderUniverse.vue' /* webpackChunkName: "components/atoms-common-atom-loader-universe" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLoadingError = () => import('../../components/atoms/common/AtomLoadingError.vue' /* webpackChunkName: "components/atoms-common-atom-loading-error" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLocaleSwitch = () => import('../../components/atoms/common/AtomLocaleSwitch.vue' /* webpackChunkName: "components/atoms-common-atom-locale-switch" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLogo = () => import('../../components/atoms/common/AtomLogo.vue' /* webpackChunkName: "components/atoms-common-atom-logo" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLottieAnimation = () => import('../../components/atoms/common/AtomLottieAnimation.vue' /* webpackChunkName: "components/atoms-common-atom-lottie-animation" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomMenuItem = () => import('../../components/atoms/common/AtomMenuItem.vue' /* webpackChunkName: "components/atoms-common-atom-menu-item" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomPerson = () => import('../../components/atoms/common/AtomPerson.vue' /* webpackChunkName: "components/atoms-common-atom-person" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomRadio = () => import('../../components/atoms/common/AtomRadio.vue' /* webpackChunkName: "components/atoms-common-atom-radio" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomScrollUp = () => import('../../components/atoms/common/AtomScrollUp.vue' /* webpackChunkName: "components/atoms-common-atom-scroll-up" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomSelect = () => import('../../components/atoms/common/AtomSelect.vue' /* webpackChunkName: "components/atoms-common-atom-select" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomSpecsInfo = () => import('../../components/atoms/common/AtomSpecsInfo.vue' /* webpackChunkName: "components/atoms-common-atom-specs-info" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomTitle = () => import('../../components/atoms/common/AtomTitle.vue' /* webpackChunkName: "components/atoms-common-atom-title" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomTitleAndDescription = () => import('../../components/atoms/common/AtomTitleAndDescription.vue' /* webpackChunkName: "components/atoms-common-atom-title-and-description" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomToggle = () => import('../../components/atoms/common/AtomToggle.vue' /* webpackChunkName: "components/atoms-common-atom-toggle" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomVerticalLine = () => import('../../components/atoms/common/AtomVerticalLine.vue' /* webpackChunkName: "components/atoms-common-atom-vertical-line" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomContact = () => import('../../components/atoms/portfolio/AtomContact.vue' /* webpackChunkName: "components/atoms-portfolio-atom-contact" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomCreator = () => import('../../components/atoms/portfolio/AtomCreator.vue' /* webpackChunkName: "components/atoms-portfolio-atom-creator" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomListCircle = () => import('../../components/atoms/portfolio/AtomListCircle.vue' /* webpackChunkName: "components/atoms-portfolio-atom-list-circle" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomPartner = () => import('../../components/atoms/portfolio/AtomPartner.vue' /* webpackChunkName: "components/atoms-portfolio-atom-partner" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomProjectPortfolio = () => import('../../components/atoms/portfolio/AtomProjectPortfolio.vue' /* webpackChunkName: "components/atoms-portfolio-atom-project-portfolio" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomProjectSliderBackground = () => import('../../components/atoms/portfolio/AtomProjectSliderBackground.vue' /* webpackChunkName: "components/atoms-portfolio-atom-project-slider-background" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomProjectSliderThumb = () => import('../../components/atoms/portfolio/AtomProjectSliderThumb.vue' /* webpackChunkName: "components/atoms-portfolio-atom-project-slider-thumb" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomTeam = () => import('../../components/atoms/portfolio/AtomTeam.vue' /* webpackChunkName: "components/atoms-portfolio-atom-team" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectAtomCategorySpecs = () => import('../../components/atoms/project/AtomCategorySpecs.vue' /* webpackChunkName: "components/atoms-project-atom-category-specs" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectAtomLocationType = () => import('../../components/atoms/project/AtomLocationType.vue' /* webpackChunkName: "components/atoms-project-atom-location-type" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectAtomRoute = () => import('../../components/atoms/project/AtomRoute.vue' /* webpackChunkName: "components/atoms-project-atom-route" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismCertifications = () => import('../../components/organisms/common/OrganismCertifications.vue' /* webpackChunkName: "components/organisms-common-organism-certifications" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismContentSlider = () => import('../../components/organisms/common/OrganismContentSlider.vue' /* webpackChunkName: "components/organisms-common-organism-content-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismContentSliderBrochure = () => import('../../components/organisms/common/OrganismContentSliderBrochure.vue' /* webpackChunkName: "components/organisms-common-organism-content-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismDrawer = () => import('../../components/organisms/common/OrganismDrawer.vue' /* webpackChunkName: "components/organisms-common-organism-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismExplore = () => import('../../components/organisms/common/OrganismExplore.vue' /* webpackChunkName: "components/organisms-common-organism-explore" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismImageSlider = () => import('../../components/organisms/common/OrganismImageSlider.vue' /* webpackChunkName: "components/organisms-common-organism-image-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismImageSliderV2 = () => import('../../components/organisms/common/OrganismImageSliderV2.vue' /* webpackChunkName: "components/organisms-common-organism-image-slider-v2" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismListCircle = () => import('../../components/organisms/common/OrganismListCircle.vue' /* webpackChunkName: "components/organisms-common-organism-list-circle" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismPageLoader = () => import('../../components/organisms/common/OrganismPageLoader.vue' /* webpackChunkName: "components/organisms-common-organism-page-loader" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismParkingAccess = () => import('../../components/organisms/common/OrganismParkingAccess.vue' /* webpackChunkName: "components/organisms-common-organism-parking-access" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismParkingAccessV2 = () => import('../../components/organisms/common/OrganismParkingAccessV2.vue' /* webpackChunkName: "components/organisms-common-organism-parking-access-v2" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismSidebarAccordion = () => import('../../components/organisms/common/OrganismSidebarAccordion.vue' /* webpackChunkName: "components/organisms-common-organism-sidebar-accordion" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismSlider = () => import('../../components/organisms/common/OrganismSlider.vue' /* webpackChunkName: "components/organisms-common-organism-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismStatementsSlider = () => import('../../components/organisms/common/OrganismStatementsSlider.vue' /* webpackChunkName: "components/organisms-common-organism-statements-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismTitleAndDescription = () => import('../../components/organisms/common/OrganismTitleAndDescription.vue' /* webpackChunkName: "components/organisms-common-organism-title-and-description" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismCreator = () => import('../../components/organisms/portfolio/OrganismCreator.vue' /* webpackChunkName: "components/organisms-portfolio-organism-creator" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismPartner = () => import('../../components/organisms/portfolio/OrganismPartner.vue' /* webpackChunkName: "components/organisms-portfolio-organism-partner" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismProjectPortfolio = () => import('../../components/organisms/portfolio/OrganismProjectPortfolio.vue' /* webpackChunkName: "components/organisms-portfolio-organism-project-portfolio" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismProjectSlider = () => import('../../components/organisms/portfolio/OrganismProjectSlider.vue' /* webpackChunkName: "components/organisms-portfolio-organism-project-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismTeam = () => import('../../components/organisms/portfolio/OrganismTeam.vue' /* webpackChunkName: "components/organisms-portfolio-organism-team" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismContactForm = () => import('../../components/organisms/project/OrganismContactForm.vue' /* webpackChunkName: "components/organisms-project-organism-contact-form" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismDownloadBrochureModal = () => import('../../components/organisms/project/OrganismDownloadBrochureModal.vue' /* webpackChunkName: "components/organisms-project-organism-download-brochure-modal" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismHighlightSpace = () => import('../../components/organisms/project/OrganismHighlightSpace.vue' /* webpackChunkName: "components/organisms-project-organism-highlight-space" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismLoader = () => import('../../components/organisms/project/OrganismLoader.vue' /* webpackChunkName: "components/organisms-project-organism-loader" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismLocation = () => import('../../components/organisms/project/OrganismLocation.vue' /* webpackChunkName: "components/organisms-project-organism-location" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismPins = () => import('../../components/organisms/project/OrganismPins.vue' /* webpackChunkName: "components/organisms-project-organism-pins" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismRequestOfferModal = () => import('../../components/organisms/project/OrganismRequestOfferModal.vue' /* webpackChunkName: "components/organisms-project-organism-request-offer-modal" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismTabsTechnicalSpecs = () => import('../../components/organisms/project/OrganismTabsTechnicalSpecs.vue' /* webpackChunkName: "components/organisms-project-organism-tabs-technical-specs" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismTechnicalSpecs = () => import('../../components/organisms/project/OrganismTechnicalSpecs.vue' /* webpackChunkName: "components/organisms-project-organism-technical-specs" */).then(c => wrapFunctional(c.default || c))
export const OrganismsUniverseOrganismTutorial = () => import('../../components/organisms/universe/OrganismTutorial.vue' /* webpackChunkName: "components/organisms-universe-organism-tutorial" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionCreators = () => import('../../components/sections/landing/SectionCreators.vue' /* webpackChunkName: "components/sections-landing-section-creators" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionHero = () => import('../../components/sections/landing/SectionHero.vue' /* webpackChunkName: "components/sections-landing-section-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionPartners = () => import('../../components/sections/landing/SectionPartners.vue' /* webpackChunkName: "components/sections-landing-section-partners" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionPortfolioMap = () => import('../../components/sections/landing/SectionPortfolioMap.vue' /* webpackChunkName: "components/sections-landing-section-portfolio-map" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionSelector = () => import('../../components/sections/landing/SectionSelector.vue' /* webpackChunkName: "components/sections-landing-section-selector" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionTeam = () => import('../../components/sections/landing/SectionTeam.vue' /* webpackChunkName: "components/sections-landing-section-team" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionUniverse = () => import('../../components/sections/landing/SectionUniverse.vue' /* webpackChunkName: "components/sections-landing-section-universe" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionUniverseMap = () => import('../../components/sections/landing/SectionUniverseMap.vue' /* webpackChunkName: "components/sections-landing-section-universe-map" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionVision = () => import('../../components/sections/landing/SectionVision.vue' /* webpackChunkName: "components/sections-landing-section-vision" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionWhy = () => import('../../components/sections/landing/SectionWhy.vue' /* webpackChunkName: "components/sections-landing-section-why" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionBackgroundImage = () => import('../../components/sections/general/SectionBackgroundImage.vue' /* webpackChunkName: "components/sections-general-section-background-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionDiscoverArea = () => import('../../components/sections/general/SectionDiscoverArea.vue' /* webpackChunkName: "components/sections-general-section-discover-area" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionExploreBuilding = () => import('../../components/sections/general/SectionExploreBuilding.vue' /* webpackChunkName: "components/sections-general-section-explore-building" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionFewFacts = () => import('../../components/sections/general/SectionFewFacts.vue' /* webpackChunkName: "components/sections-general-section-few-facts" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionIconsDescriptions = () => import('../../components/sections/general/SectionIconsDescriptions.vue' /* webpackChunkName: "components/sections-general-section-icons-descriptions" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionImageSide = () => import('../../components/sections/general/SectionImageSide.vue' /* webpackChunkName: "components/sections-general-section-image-side" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionInfoAndImage = () => import('../../components/sections/general/SectionInfoAndImage.vue' /* webpackChunkName: "components/sections-general-section-info-and-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionPurposeAndValues = () => import('../../components/sections/general/SectionPurposeAndValues.vue' /* webpackChunkName: "components/sections-general-section-purpose-and-values" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionStatements = () => import('../../components/sections/general/SectionStatements.vue' /* webpackChunkName: "components/sections-general-section-statements" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionVideo = () => import('../../components/sections/general/SectionVideo.vue' /* webpackChunkName: "components/sections-general-section-video" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionBrochureButton = () => import('../../components/sections/project/SectionBrochureButton.vue' /* webpackChunkName: "components/sections-project-section-brochure-button" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionBuilding = () => import('../../components/sections/project/SectionBuilding.vue' /* webpackChunkName: "components/sections-project-section-building" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionContact = () => import('../../components/sections/project/SectionContact.vue' /* webpackChunkName: "components/sections-project-section-contact" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionFeatures = () => import('../../components/sections/project/SectionFeatures.vue' /* webpackChunkName: "components/sections-project-section-features" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionGallery = () => import('../../components/sections/project/SectionGallery.vue' /* webpackChunkName: "components/sections-project-section-gallery" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionImageList = () => import('../../components/sections/project/SectionImageList.vue' /* webpackChunkName: "components/sections-project-section-image-list" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionImageOrVideo = () => import('../../components/sections/project/SectionImageOrVideo.vue' /* webpackChunkName: "components/sections-project-section-image-or-video" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionLocation = () => import('../../components/sections/project/SectionLocation.vue' /* webpackChunkName: "components/sections-project-section-location" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionParking = () => import('../../components/sections/project/SectionParking.vue' /* webpackChunkName: "components/sections-project-section-parking" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionSelector = () => import('../../components/sections/project/SectionSelector.vue' /* webpackChunkName: "components/sections-project-section-selector" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionSpecs = () => import('../../components/sections/project/SectionSpecs.vue' /* webpackChunkName: "components/sections-project-section-specs" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionSustainability = () => import('../../components/sections/project/SectionSustainability.vue' /* webpackChunkName: "components/sections-project-section-sustainability" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeCertifications = () => import('../../components/molecules/common/MoleculeCertifications.vue' /* webpackChunkName: "components/molecules-common-molecule-certifications" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContactForm = () => import('../../components/molecules/common/MoleculeContactForm.vue' /* webpackChunkName: "components/molecules-common-molecule-contact-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContentSlider = () => import('../../components/molecules/common/MoleculeContentSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-content-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContentSliderBrochure = () => import('../../components/molecules/common/MoleculeContentSliderBrochure.vue' /* webpackChunkName: "components/molecules-common-molecule-content-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContentThumbSliderBrochure = () => import('../../components/molecules/common/MoleculeContentThumbSliderBrochure.vue' /* webpackChunkName: "components/molecules-common-molecule-content-thumb-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeExplore = () => import('../../components/molecules/common/MoleculeExplore.vue' /* webpackChunkName: "components/molecules-common-molecule-explore" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeGoogleMap = () => import('../../components/molecules/common/MoleculeGoogleMap.vue' /* webpackChunkName: "components/molecules-common-molecule-google-map" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeHorizontalMenu = () => import('../../components/molecules/common/MoleculeHorizontalMenu.vue' /* webpackChunkName: "components/molecules-common-molecule-horizontal-menu" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeIconListWithTexts = () => import('../../components/molecules/common/MoleculeIconListWithTexts.vue' /* webpackChunkName: "components/molecules-common-molecule-icon-list-with-texts" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeImageSlider = () => import('../../components/molecules/common/MoleculeImageSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-image-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeImageSliderWithThumbnails = () => import('../../components/molecules/common/MoleculeImageSliderWithThumbnails.vue' /* webpackChunkName: "components/molecules-common-molecule-image-slider-with-thumbnails" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeImageThumbSlider = () => import('../../components/molecules/common/MoleculeImageThumbSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-image-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeListCircle = () => import('../../components/molecules/common/MoleculeListCircle.vue' /* webpackChunkName: "components/molecules-common-molecule-list-circle" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeSidebarAccordionResults = () => import('../../components/molecules/common/MoleculeSidebarAccordionResults.vue' /* webpackChunkName: "components/molecules-common-molecule-sidebar-accordion-results" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeSliderSlide = () => import('../../components/molecules/common/MoleculeSliderSlide.vue' /* webpackChunkName: "components/molecules-common-molecule-slider-slide" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeSliderWithThumbnails = () => import('../../components/molecules/common/MoleculeSliderWithThumbnails.vue' /* webpackChunkName: "components/molecules-common-molecule-slider-with-thumbnails" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeStatementsSlider = () => import('../../components/molecules/common/MoleculeStatementsSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-statements-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeTitleAndDescription = () => import('../../components/molecules/common/MoleculeTitleAndDescription.vue' /* webpackChunkName: "components/molecules-common-molecule-title-and-description" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeVideoSliderWithThumbnails = () => import('../../components/molecules/common/MoleculeVideoSliderWithThumbnails.vue' /* webpackChunkName: "components/molecules-common-molecule-video-slider-with-thumbnails" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeCreator = () => import('../../components/molecules/portfolio/MoleculeCreator.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-creator" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculePartner = () => import('../../components/molecules/portfolio/MoleculePartner.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-partner" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeProjectPortfolio = () => import('../../components/molecules/portfolio/MoleculeProjectPortfolio.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-project-portfolio" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeProjectSliderBackground = () => import('../../components/molecules/portfolio/MoleculeProjectSliderBackground.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-project-slider-background" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeProjectSliderThumb = () => import('../../components/molecules/portfolio/MoleculeProjectSliderThumb.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-project-slider-thumb" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeTeam = () => import('../../components/molecules/portfolio/MoleculeTeam.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-team" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeDownloadBrochure = () => import('../../components/molecules/project/MoleculeDownloadBrochure.vue' /* webpackChunkName: "components/molecules-project-molecule-download-brochure" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeFitoutSelector = () => import('../../components/molecules/project/MoleculeFitoutSelector.vue' /* webpackChunkName: "components/molecules-project-molecule-fitout-selector" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculePin = () => import('../../components/molecules/project/MoleculePin.vue' /* webpackChunkName: "components/molecules-project-molecule-pin" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculePinExpand = () => import('../../components/molecules/project/MoleculePinExpand.vue' /* webpackChunkName: "components/molecules-project-molecule-pin-expand" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculePopupHeader = () => import('../../components/molecules/project/MoleculePopupHeader.vue' /* webpackChunkName: "components/molecules-project-molecule-popup-header" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculePopupResult = () => import('../../components/molecules/project/MoleculePopupResult.vue' /* webpackChunkName: "components/molecules-project-molecule-popup-result" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculePopupResults = () => import('../../components/molecules/project/MoleculePopupResults.vue' /* webpackChunkName: "components/molecules-project-molecule-popup-results" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeRequestOffer = () => import('../../components/molecules/project/MoleculeRequestOffer.vue' /* webpackChunkName: "components/molecules-project-molecule-request-offer" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeSendRequest = () => import('../../components/molecules/project/MoleculeSendRequest.vue' /* webpackChunkName: "components/molecules-project-molecule-send-request" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeSidebarRoutes = () => import('../../components/molecules/project/MoleculeSidebarRoutes.vue' /* webpackChunkName: "components/molecules-project-molecule-sidebar-routes" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSlider2AtomArrowButtonSlider = () => import('../../components/atoms/common/Slider2/AtomArrowButtonSlider.vue' /* webpackChunkName: "components/atoms-common-slider2-atom-arrow-button-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAvailabilityAtomCollapsibleItem = () => import('../../components/atoms/common/availability/AtomCollapsibleItem.vue' /* webpackChunkName: "components/atoms-common-availability-atom-collapsible-item" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeAtomDoubleRangeSlider = () => import('../../components/atoms/common/range/AtomDoubleRangeSlider.vue' /* webpackChunkName: "components/atoms-common-range-atom-double-range-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeAtomRangeSlider = () => import('../../components/atoms/common/range/AtomRangeSlider.vue' /* webpackChunkName: "components/atoms-common-range-atom-range-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomContentSlider = () => import('../../components/atoms/common/slider/AtomContentSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-content-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomContentSliderBrochure = () => import('../../components/atoms/common/slider/AtomContentSliderBrochure.vue' /* webpackChunkName: "components/atoms-common-slider-atom-content-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomContentThumbSliderBrochure = () => import('../../components/atoms/common/slider/AtomContentThumbSliderBrochure.vue' /* webpackChunkName: "components/atoms-common-slider-atom-content-thumb-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomImageSlider = () => import('../../components/atoms/common/slider/AtomImageSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-image-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomImageThumbSlider = () => import('../../components/atoms/common/slider/AtomImageThumbSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-image-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomStatementsSlider = () => import('../../components/atoms/common/slider/AtomStatementsSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-statements-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomVideoSlider = () => import('../../components/atoms/common/slider/AtomVideoSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-video-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomVideoThumbSlider = () => import('../../components/atoms/common/slider/AtomVideoThumbSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-video-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinFitoutButtons = () => import('../../components/atoms/project/building/AtomPinFitoutButtons.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-fitout-buttons" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinIconText = () => import('../../components/atoms/project/building/AtomPinIconText.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-icon-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinImage = () => import('../../components/atoms/project/building/AtomPinImage.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-image" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinInfoContent = () => import('../../components/atoms/project/building/AtomPinInfoContent.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-info-content" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinSimpleText = () => import('../../components/atoms/project/building/AtomPinSimpleText.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-simple-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinVideo = () => import('../../components/atoms/project/building/AtomPinVideo.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-video" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomRequestOfferButton = () => import('../../components/atoms/project/building/AtomRequestOfferButton.vue' /* webpackChunkName: "components/atoms-project-building-atom-request-offer-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomSidebarInfo = () => import('../../components/atoms/project/building/AtomSidebarInfo.vue' /* webpackChunkName: "components/atoms-project-building-atom-sidebar-info" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomSidebarTitle = () => import('../../components/atoms/project/building/AtomSidebarTitle.vue' /* webpackChunkName: "components/atoms-project-building-atom-sidebar-title" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomTrashButton = () => import('../../components/atoms/project/building/AtomTrashButton.vue' /* webpackChunkName: "components/atoms-project-building-atom-trash-button" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonAvailabilityOrganismAvailabilityDrawer = () => import('../../components/organisms/common/availability/OrganismAvailabilityDrawer.vue' /* webpackChunkName: "components/organisms-common-availability-organism-availability-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonAvailabilityOrganismShortlistDrawer = () => import('../../components/organisms/common/availability/OrganismShortlistDrawer.vue' /* webpackChunkName: "components/organisms-common-availability-organism-shortlist-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonAvailabilityOrganismSpaceDetails = () => import('../../components/organisms/common/availability/OrganismSpaceDetails.vue' /* webpackChunkName: "components/organisms-common-availability-organism-space-details" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismBuilding = () => import('../../components/organisms/project/building/OrganismBuilding.vue' /* webpackChunkName: "components/organisms-project-building-organism-building" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismCartSpacesBuilding = () => import('../../components/organisms/project/building/OrganismCartSpacesBuilding.vue' /* webpackChunkName: "components/organisms-project-building-organism-cart-spaces-building" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismGuidedTourLauncher = () => import('../../components/organisms/project/building/OrganismGuidedTourLauncher.vue' /* webpackChunkName: "components/organisms-project-building-organism-guided-tour-launcher" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismGuidedTourPresentationDrawer = () => import('../../components/organisms/project/building/OrganismGuidedTourPresentationDrawer.vue' /* webpackChunkName: "components/organisms-project-building-organism-guided-tour-presentation-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismGuidedTourTimeline = () => import('../../components/organisms/project/building/OrganismGuidedTourTimeline.vue' /* webpackChunkName: "components/organisms-project-building-organism-guided-tour-timeline" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismPinInfoBox = () => import('../../components/organisms/project/building/OrganismPinInfoBox.vue' /* webpackChunkName: "components/organisms-project-building-organism-pin-info-box" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismSelectedSpaceMobile = () => import('../../components/organisms/project/building/OrganismSelectedSpaceMobile.vue' /* webpackChunkName: "components/organisms-project-building-organism-selected-space-mobile" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismSidebarBuilding = () => import('../../components/organisms/project/building/OrganismSidebarBuilding.vue' /* webpackChunkName: "components/organisms-project-building-organism-sidebar-building" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismTutorial = () => import('../../components/organisms/project/building/OrganismTutorial.vue' /* webpackChunkName: "components/organisms-project-building-organism-tutorial" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSustainabilitySectionSustainabilityV1 = () => import('../../components/sections/general/sustainability/SectionSustainabilityV1.vue' /* webpackChunkName: "components/sections-general-sustainability-section-sustainability-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSustainabilitySectionSustainabilityV2 = () => import('../../components/sections/general/sustainability/SectionSustainabilityV2.vue' /* webpackChunkName: "components/sections-general-sustainability-section-sustainability-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralContactSectionContactV1 = () => import('../../components/sections/general/contact/SectionContactV1.vue' /* webpackChunkName: "components/sections-general-contact-section-contact-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralContactSectionContactV2 = () => import('../../components/sections/general/contact/SectionContactV2.vue' /* webpackChunkName: "components/sections-general-contact-section-contact-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectGallerySectionGalleryV1 = () => import('../../components/sections/project/gallery/SectionGalleryV1.vue' /* webpackChunkName: "components/sections-project-gallery-section-gallery-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectGallerySectionGalleryV2 = () => import('../../components/sections/project/gallery/SectionGalleryV2.vue' /* webpackChunkName: "components/sections-project-gallery-section-gallery-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectParkingSectionParkingV1 = () => import('../../components/sections/project/parking/SectionParkingV1.vue' /* webpackChunkName: "components/sections-project-parking-section-parking-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectParkingSectionParkingV2 = () => import('../../components/sections/project/parking/SectionParkingV2.vue' /* webpackChunkName: "components/sections-project-parking-section-parking-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSpecsSectionSpecsV1 = () => import('../../components/sections/project/specs/SectionSpecsV1.vue' /* webpackChunkName: "components/sections-project-specs-section-specs-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSpecsSectionSpecsV2 = () => import('../../components/sections/project/specs/SectionSpecsV2.vue' /* webpackChunkName: "components/sections-project-specs-section-specs-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectLocationSectionLocationV1 = () => import('../../components/sections/project/location/SectionLocationV1.vue' /* webpackChunkName: "components/sections-project-location-section-location-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectLocationSectionLocationV2 = () => import('../../components/sections/project/location/SectionLocationV2.vue' /* webpackChunkName: "components/sections-project-location-section-location-v2" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonAvailabilityMoleculeFilters = () => import('../../components/molecules/common/availability/MoleculeFilters.vue' /* webpackChunkName: "components/molecules-common-availability-molecule-filters" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonAvailabilityMoleculeMultipleOptions = () => import('../../components/molecules/common/availability/MoleculeMultipleOptions.vue' /* webpackChunkName: "components/molecules-common-availability-molecule-multiple-options" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeCartSpaces = () => import('../../components/molecules/project/building/MoleculeCartSpaces.vue' /* webpackChunkName: "components/molecules-project-building-molecule-cart-spaces" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeGuidedTourTimeline = () => import('../../components/molecules/project/building/MoleculeGuidedTourTimeline.vue' /* webpackChunkName: "components/molecules-project-building-molecule-guided-tour-timeline" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculePinInfoContent = () => import('../../components/molecules/project/building/MoleculePinInfoContent.vue' /* webpackChunkName: "components/molecules-project-building-molecule-pin-info-content" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSidebarHeader = () => import('../../components/molecules/project/building/MoleculeSidebarHeader.vue' /* webpackChunkName: "components/molecules-project-building-molecule-sidebar-header" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSidebarResult = () => import('../../components/molecules/project/building/MoleculeSidebarResult.vue' /* webpackChunkName: "components/molecules-project-building-molecule-sidebar-result" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSidebarResults = () => import('../../components/molecules/project/building/MoleculeSidebarResults.vue' /* webpackChunkName: "components/molecules-project-building-molecule-sidebar-results" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonFiltersAmenitiesAtomOption = () => import('../../components/atoms/common/filters/amenities/AtomOption.vue' /* webpackChunkName: "components/atoms-common-filters-amenities-atom-option" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeUtilsArraysEqual = () => import('../../components/atoms/common/range/utils/arraysEqual.ts' /* webpackChunkName: "components/atoms-common-range-utils-arrays-equal" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeUtilsIsNullish = () => import('../../components/atoms/common/range/utils/isNullish.ts' /* webpackChunkName: "components/atoms-common-range-utils-is-nullish" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeComposablesUseClasses = () => import('../../components/atoms/common/range/composables/useClasses.ts' /* webpackChunkName: "components/atoms-common-range-composables-use-classes" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeComposablesUseSlider = () => import('../../components/atoms/common/range/composables/useSlider.ts' /* webpackChunkName: "components/atoms-common-range-composables-use-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeComposablesUseTooltip = () => import('../../components/atoms/common/range/composables/useTooltip.ts' /* webpackChunkName: "components/atoms-common-range-composables-use-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonRangeComposablesUseValue = () => import('../../components/atoms/common/range/composables/useValue.ts' /* webpackChunkName: "components/atoms-common-range-composables-use-value" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourMoleculeContentBlocks = () => import('../../components/molecules/project/building/guidedTour/MoleculeContentBlocks.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-molecule-content-blocks" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockEmbed = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockEmbed.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-embed" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockIframe = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockIframe.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-iframe" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockImage = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockImage.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-image" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockText = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockText.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-text" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockVideo = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockVideo.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-video" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
